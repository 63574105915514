import React, {useState, useEffect, useRef} from 'react';
import DatePicker from "react-datepicker";
import {
    findSalesByStatus
} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {formatDateString, formatDateTimeString} from "../../utils/utils";
import 'react-datepicker/dist/react-datepicker.css';
import eyeIcon from '../../img/icons8-eye-24.png';
import SaleManagerSeeItems from "./SaleManagerSeeItems";
import Cookies from "js-cookie";


const apiUrl = Config.apiUrl;
const SalesList = () => {
    const [sales, setSales] = useState([]);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [queryString, setQueryString] = useState('');
    const [status, setStatus] = useState(1);
    const [, setLoading] = useRecoilState(loadingAtom);
    const [totalValue, setTotalValue] = useState(0);
    const [seeItems, setSeeItems] = useState(false);
    const [currentSeeSale, setCurrentSeeSale] = useState(undefined);

    async function makeQuery() {
        try {
            setLoading(true);
            const newStartDate = startDate ? startDate : new Date(1900, 0, 1);
            const newEndDate = endDate ? endDate : new Date(2100, 0, 1);
            let sales = await findSalesByStatus(newStartDate, newEndDate, status);
            if(queryString !== undefined && queryString.trim() !== '') {
                sales = sales.filter((s) =>
                    (s.id === Number(queryString))
                    || (s.customerName &&  s.customerName.toUpperCase().includes(queryString))
                    || (s.customer &&  s.customer.name.toUpperCase().includes(queryString))
                    || (s.table &&  s.table.name.toUpperCase().includes(queryString))
                );
            }

            const newTotalValue = sales.reduce((accumulator, currentSale) => {
                return accumulator + currentSale.saleItems.reduce((accumulator2, currentItem) => {
                    return accumulator2 + currentItem.total;
                }, 0);
            }, 0);
            setTotalValue(newTotalValue);
            setSales(sales);

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        makeQuery();
    }, []);

    const handleStartDateChange = (date) => {
        setStartDate(date);
    };

    const handleEndDateChange = (date) => {
        setEndDate(date);
    };

    const userCookies = Cookies.get('user');
    let isUserAdmin = false;
    if (userCookies) {
        const user = JSON.parse(userCookies);
        isUserAdmin = user.email === 'renanedpedroso@gmail.com' || user.email === 'sgesistemaspc@gmail.com';
    }

    function removeSpecialChars(str) {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    }
    const handleInputChange = (e) => {
        const string = removeSpecialChars(e.target.value).toUpperCase();
        setQueryString(string);
    };

    return (


        seeItems === true ? <SaleManagerSeeItems sale={currentSeeSale} onClose={() => setSeeItems(false)} onRemove={makeQuery}/> :
            <>
                <div className="header-label">Consultar Pedidos</div>

                <div style={{marginTop: '5px', alignItems: 'center'}}>
                    <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                        <DatePicker
                            className="datepicker"
                            selected={startDate}
                            onChange={handleStartDateChange}
                            isClearable
                            timeFormat="HH:mm"
                            dateFormat="dd/MM/yyyy HH:mm"
                        />
                        até
                        <DatePicker
                            className="datepicker"
                            selected={endDate}
                            onChange={handleEndDateChange}
                            dateFormat="dd/MM/yyyy"
                            isClearable
                        />
                    </div>
                    <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                        <input style={{width: '100%'}} type="text" value={queryString} onChange={handleInputChange}/>
                    </div>
                    <div style={{marginTop: '5px', display: 'flex', alignItems: 'center'}}>
                        <select style={{flexGrow: 1}} value={status}
                                onChange={(e) => setStatus(parseInt(e.target.value))}>
                            <option key={-1} value={-1}>Todos</option>
                            <option key={1} value={1}>Pendentes</option>
                            <option key={2} value={2}>Pagos</option>
                        </select>

                        <button onClick={makeQuery}>Consultar</button>
                    </div>
                </div>

                <div style={{flexGrow: 1, overflowY: "auto"}}>
                    <table style={{width: '100%'}}>
                        <colgroup>
                            <col style={{width: '10px'}}/>
                            <col style={{width: '250px'}}/>
                            <col style={{width: '150px'}}/>
                            <col style={{width: '150px'}}/>
                            <col style={{width: '150px'}}/>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>Cód.</th>
                            <th>Cliente</th>
                            <th>Mesa</th>
                            <th>Data</th>
                            <th/>
                        </tr>
                        </thead>
                        <tbody style={{overflowY: 'auto'}}>
                        {sales.map((sale, index) => (
                            <tr key={index} style={{height: '41px'}}>
                                <td>
                                    <div style={{textAlign: "center"}} key={index}>{sale.id}</div>
                                </td>
                                <td>
                                    <div
                                        key={index}>{sale.customer ? sale.customer.name : sale.customerName}</div>
                                </td>
                                <td>
                                    <div key={index}>{sale.table ? sale.table.name : ''}</div>
                                </td>
                                <td>
                                    <div key={index}>{formatDateTimeString(sale.saleDate)}</div>
                                </td>
                                <td>

                                    <button onClick={() => {
                                        setSeeItems(true);
                                        setCurrentSeeSale(sale);
                                    }}
                                            style={{padding: '5px 5px 0px 5px'}}
                                    >
                                        <img src={eyeIcon} alt="Home" style={{width: '20px', height: '20px'}}/>
                                    </button>

                                </td>

                            </tr>
                        ))}
                        </tbody>
                        {sales.length === 0 &&
                            <tfoot>
                            <tr>
                                <td colSpan={5}>
                                    Nenhum item encontrado
                                </td>
                            </tr>
                            </tfoot>
                        }
                    </table>
                </div>
                {isUserAdmin &&
                    <div style={{marginTop: '5px', alignItems: 'center'}}>

                        <div className="total-label" style={{textAlign: 'right'}}>Valor Total:
                            R$ {totalValue.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                        </div>
                    </div>
                }
            </>

    );
};

export default SalesList;
