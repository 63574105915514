import React, {useEffect, useRef, useState} from 'react';
import {findProductsByQueryString, forceSalePrinting} from "../../utils/requestUtils";
import Config from "../../core/config";
import {useRecoilState} from "recoil";
import {loadingAtom} from "../../atoms";
import Cookies from "js-cookie";
import {useNavigate} from "react-router-dom";
import ConfirmButton from "../common/ConfirmButton";
import ActionButtonsManagerView from "../common/ActionButtonsManagerView";
import {makeRequest} from "../../utils/httpRequest";
import {
    adjustDateToBrowserTimeZone,
    calculateDaysDifference,
    formatDateTimeString,
    getOnlyDate
} from "../../utils/utils";

const apiUrl = Config.apiUrl;
const SaleManagerSeeItems = ({sale, onClose, onRemove}) => {

    const [totalValue, setTotalValue] = useState(0);

    useEffect(() => {

        const handleBackButton = (event) => {
            event.preventDefault();
            onClose();
        };
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton);

        const newTotalValue = sale.saleItems.reduce((accumulator2, currentItem) => {
            return accumulator2 + currentItem.total;
        }, 0);
        setTotalValue(newTotalValue);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const userCookies = Cookies.get('user');
    let isUserAdmin = false;
    if (userCookies) {
        const user = JSON.parse(userCookies);
        isUserAdmin = user.email === 'renanedpedroso@gmail.com' || user.email === 'sgesistemaspc@gmail.com'
    }

    async function removeSale() {
        /* eslint-disable no-restricted-globals */
        if (!confirm('Essa operação não poderá ser revertida. Deseja confinuar?')) {
            return;
        }
        try {
            const url = apiUrl + '/api/sales/' + sale.id;
            await makeRequest(url, 'DELETE', {'Useruuid': Cookies.get('userUUID')});
            onClose();
            onRemove();
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    return (
        <>
            <div className="header-label">Pedido: {sale.id}</div>
            <div>Data: {formatDateTimeString(sale.saleDate)}</div>
            <div>{sale.customer ? sale.customer.name : sale.customerName}</div>
            {sale.table && <div>{sale.table.name}</div>}
            <table style={{width: '100%'}}>
                <colgroup>
                    <col style={{width: '500px'}}/>
                    <col style={{width: '50px'}}/>
                    <col style={{width: '50px'}}/>
                </colgroup>
                <thead>
                <tr>
                    <th>
                        Nome
                    </th>
                    <th>
                        Qtd
                    </th>
                    <th>
                        Preço
                    </th>
                </tr>
                </thead>
                <tbody style={{overflowY: 'auto'}}>
                {sale.saleItems.map((item, index) => (
                    <tr key={index}
                        style={{backgroundColor: item.quantity > 0 ? 'var(--warning-color)' : item.quantity < 0 ? 'var(--error-color)' : 'white'}}>
                        <td>
                            <div
                                key={index}>{item.productName}</div>
                        </td>
                        <td>
                            {item.quantity ? item.quantity : 0}
                        </td>
                        <td>
                            <div style={{textAlign: 'right'}}>{item.product.salePrice.toLocaleString('pt-BR', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}
                            </div>
                        </td>
                    </tr>
                ))}
                </tbody>
                {sale.saleItems.length === 0 &&
                    <tfoot>
                    <tr>
                        <td colSpan={5}>
                            Nenhum item encontrado
                        </td>
                    </tr>
                    </tfoot>
                }
            </table>
            <div style={{marginTop: '5px', alignItems: 'center'}}>

                <div className="total-label" style={{textAlign: 'right', fontSize: '30px', color: '#cc5545'}}>Valor
                    Total:
                    R$ {totalValue.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}
                </div>
            </div>
            <ActionButtonsManagerView>
                <button onClick={onClose} className="back-button">Voltar</button>
                <button onClick={async () => {
                    await forceSalePrinting([sale.id]);
                }}>Reimprimir
                </button>
                {isUserAdmin && <button onClick={removeSale} className="remove-button">Excluir</button>}
            </ActionButtonsManagerView>
        </>
    );
}

export default SaleManagerSeeItems;
